.recommendation-collapse {
  background: transparent;
  .ant-collapse-header {
    width: fit-content;
  }
  .ant-collapse-item {
    border: none;
  }
  & > .ant-collapse-item > .ant-collapse-header {
    padding: 0;
    margin-bottom: 5px;
  }
  .panel-header {
    font-weight: 500;
    width: fit-content;
    color: @label-color;
    padding: 8px 16px 8px 16px;
    border-radius: 40px;
    background: @background-color;
  }
  & .ant-collapse-content > .ant-collapse-content-box {
    padding: 0;
  }
  .previous-details-card-wrapper {
    padding: 16px;
    border: 1px solid @border-color;
    border-radius: 16px;
  }
}
.ant-modal-footer {
  border-top: none;
  padding: 0 16px 16px;
}
@media screen and (max-width: 999px) {
  .add-response-modal.ant-modal {
    max-width: 100%;
    bottom: 0;
    margin: 0;
    vertical-align: bottom;
    padding: 0;
    .ant-modal-content {
      border-end-end-radius: 0;
      border-end-start-radius: 0;
      max-height: 90vh;
      height: 90vh;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      overflow: auto;
    }
  }
}
.success-container {
  background-image: url(~assets/images/cover.png);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  position: relative;
  height: 100%;
  flex: 1 1;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  &-content {
    padding: 32px 32px;
    font-size: 14px;
    position: relative;
    z-index: 5;
    h1 {
      font-size: 32px;
      margin-bottom: 80px;
    }
    svg {
      margin-bottom: 80px;
    }
  }
}

.success-container::after {
  content: '';
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 3;
  background-color: rgba(242, 244, 247, 0.9);
}
