.project-header {
  margin-bottom: 24px;
  &-title {
    align-items: center;
    h1 {
      margin-bottom: 0px;
    }
    svg {
      fill: @primary-color;
      width: 35px;
      height: 35px;
      fill: #fc5027;
      margin-right: 15px;
      margin-bottom: 2px;
    }
  }
  &-button {
    .ant-btn {
      display: flex;
      align-items: center;
      svg {
        width: 25px;
        fill: white;
      }
      span {
        margin-left: 5px;
      }
    }
  }
}

.project-module {
  .ant-table-thead > tr > th {
    color: @grey-10;
  }
  .ant-table-tbody > tr > td {
    color: @header-text-color;
  }
}

.deactivated-color {
  background: @light-blue;
}

.ant-input-number {
  width: 100%;
  .ant-input-number-handler-wrap {
    display: none;
  }
}
.common-map {
  height: 340px;
  width: 100%;
}
.map-row {
  position: relative;
}
.map-input {
  position: absolute;
  border: none;
  z-index: 10;
  height: 40px;
  top: 10px;
  width: 350px;
  left: 10px;
  box-shadow: rgb(0 0 0 / 20%) 0px 3px 3px -2px,
    rgb(0 0 0 / 14%) 0px 3px 4px 0px, rgb(0 0 0 / 12%) 0px 1px 8px 0px;
}
.project-details {
  &-title {
    &-text {
      align-items: center;
    }
    h1 {
      margin-bottom: 0px;
      font-size: 22px;
    }
    h2 {
      margin-bottom: 0px;
      color: @primary-color;
      margin-right: 10px;
    }
    svg {
      min-width: 24px;
      width: 24px;
      height: 24px;
      margin-right: 10px;
    }
    .ant-btn {
      svg {
        margin-right: 0px;
      }
      span {
        margin-left: 10px;
      }
    }
    .edit-button-project {
      align-items: center;
      svg {
        path {
          fill: @white-color;
        }
      }
    }
    @media screen and (max-width: 999px) {
      h1 {
        font-size: 14px;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        max-width: calc(100vw - 150px);
      }
      svg {
        width: 24px;
        height: 24px;
      }
    }
    @media screen and (min-width: 1000px) {
      margin-bottom: 15px;
    }
  }
  .ant-tabs-tab {
    display: flex;
    min-width: 100px;
    padding-left: 8px;
    padding-right: 8px;
    border-radius: 8px 8px 0px 0px;
    justify-content: center;
    background: #e5e8ed;
    &-active {
      background: @white-color;
      color: @primary-color;
    }
  }
  .ant-tabs-tabpane {
    background: @white-color;
    border-radius: 0px 8px 8px 8px;
    padding: 20px 20px;
  }
  .ant-tabs-top > .ant-tabs-nav,
  .ant-tabs-bottom > .ant-tabs-nav,
  .ant-tabs-top > div > .ant-tabs-nav,
  .ant-tabs-bottom > div > .ant-tabs-nav {
    margin-bottom: 0px;
  }
}

.project-tab-details {
  .filter-search {
    .ant-select:not(.ant-select-customize-input) .ant-select-selector {
      border-radius: 40px;
      border: none;
    }

    .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
      height: 100%;
    }
    .ant-form-item-control-input-content {
      display: flex;
    }

    .ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input)
      .ant-select-selector {
      border: none;
      box-shadow: none;
    }
    .filter-div {
      display: flex;
      align-items: center;
      flex-direction: row;
      border: 1px solid #e5e8ed;
      border-radius: 40px;
      margin: 0px 16px;
      padding: 4px 8px;
      height: 32px;
      .ant-select {
        width: 100%;
      }
    }
  }
}

.project-user-modal {
  h2 {
    font-weight: 500;
  }
  .ant-row.ant-form-item {
    margin-bottom: 15px;
  }
  .ant-select-disabled.ant-select-multiple .ant-select-selection-item {
    color: @grey-10;
  }
}

.project-tab-details {
  &-header {
    margin-bottom: 20px;
    h2 {
      margin-bottom: 0px;
    }
  }
}
.instruction-tab-details {
  &-summary {
    .ant-table-wrapper {
      max-width: 750px;
    }
  }
  .report-button {
    background: @inactive-tag-background;
    &.ant-btn {
      padding-top: 7.9px !important;
    }
    svg {
      fill: @grey-10;
    }
    &:hover:not([disabled]) {
      svg {
        fill: @primary-color;
      }
    }
    &.ant-btn[disabled] {
      svg {
        fill: rgba(0, 0, 0, 0.25);
      }
      &:hover svg {
        fill: rgba(0, 0, 0, 0.25);
      }
    }
  }
  .ant-btn {
    margin-right: 5px;
    display: flex;
    align-items: center;
    svg {
      width: 26px;
    }
    span {
      margin-left: 5px;
    }
  }
  .ant-table-content {
    border: 0px;
  }
  .ant-table-thead > tr > th,
  .ant-table-tbody > tr > td {
    border-bottom: 0px;
  }

  tbody.ant-table-tbody {
    color: @header-text-color;
  }
  &-recommendation {
    .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
      width: 300px;
      border-radius: 40px;
    }
    .ant-select-arrow {
      width: 18px;
    }
    td.ant-table-cell {
      max-width: 500px;
      img.image {
        margin-right: 5px;
        flex-shrink: 0;
        cursor: pointer;
        margin-bottom: 5px;
      }
      .ant-image {
        margin-right: 5px;
      }
    }
  }
  .ant-table-thead > tr > th,
  .ant-table-tbody > tr > td,
  .ant-table tfoot > tr > th,
  .ant-table tfoot > tr > td {
    padding: 5px 5px;
  }
}

.image-none {
  display: none;
}

.ant-image-mask:hover {
  opacity: 0;
}

.eqc-tab-details,
.eqc-type-details {
  .report-button {
    border: none;
    background: @inactive-tag-background;
    &.ant-btn {
      padding-top: 7.9px !important;
    }
    svg {
      fill: @grey-10;
    }
    &:hover:not([disabled]) {
      svg {
        fill: @primary-color;
      }
    }
    &.ant-btn[disabled] {
      svg {
        fill: rgba(0, 0, 0, 0.25);
      }
      &:hover svg {
        fill: rgba(0, 0, 0, 0.25);
      }
    }
  }
  .comment-button {
    background: @text-color;
    color: @grey-1;
    border: none;
    svg {
      fill: @grey-1;
      &:hover {
        fill: @primary-color;
      }
    }
  }
  .ant-btn {
    margin-right: 5px;
    display: flex;
    align-items: center;
    span {
      margin-left: 5px;
    }
  }
}
.comment-modal-body .comment-img-wrapper img {
  max-width: 400px;
  max-height: 400px;
  margin-bottom: 20px;
  margin-right: 20px;
}

.eqc-stage-details {
  .no-remark {
    cursor: not-allowed;
    opacity: 0.5;
  }
  .has-remark {
    cursor: pointer;
    @media screen and (max-width: 999px) {
      fill: @label-color;
    }
  }
}
.auditor-comment-modal {
  min-height: 300px;
  .auditor-view-comment-wrap {
    display: flex;
    margin: 0.2rem 0 1rem 0;
    flex-direction: column;
    border-bottom: 1px solid #ddd;
    p {
      margin: 0.125rem;
      word-break: break-word;
      span {
        align-self: flex-end;
        float: right;
        text-align: center;
        font-style: italic;
        font-weight: 400;
        font-size: 12px;
      }
    }
  }
}
.project-action-btn {
  top: 5px;
  right: 0px;
  .ant-btn-round.ant-btn-icon-only {
    padding: 0;
    margin-right: 5px;
  }
}
.add-project-wrapper {
  max-width: 720px;
  margin: auto;
}
@media screen and( max-width: 999px) {
  .project-header {
    margin-bottom: 16px;
  }
  img.image {
    border-radius: 4px;
  }
  .project-tab-details-header h2 {
    margin-bottom: 0.5em;
  }
  .project-tab-details .filter-search {
    display: block;
    .filter-div {
      margin: 0px 8px 0 0;
      width: 100%;
      margin-bottom: 8px;
    }
  }
  .eqc-tab-details-summary {
    &.ant-card-bordered {
      border: none;
    }
  }
  .instruction-tab-details {
    .ant-card-bordered:not(.bordered) {
      border: none;
    }
    .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
      width: 100%;
    }
  }
  .project-module {
    &.ant-card-bordered {
      border: none;
    }
  }
  .ant-steps {
    overflow: auto;
    .ant-steps-item {
      overflow: unset;
      .ant-steps-item-title::after {
        width: 48px;
      }
    }
  }
  .ant-steps-horizontal:not(.ant-steps-label-vertical)
    .ant-steps-item:not(:first-of-type) {
    margin-left: 48px;
  }
}
